//演示环境
var requestUrl,pointsRequest;
let env = 1;

if (env == 0) {
	requestUrl = 'http://www.allnfts.cn';
	pointsRequest = "https://api.ossclub.cn/v2";
	// pointsRequest = "http://3692b10s58.zicp.vip:56246/v2";
} else {
	//线上环境
	requestUrl = 'https://nft.allnfts.cn';
	pointsRequest = "https://nft.allnfts.cn/v2";
}

export default {
	requestUrl,
	pointsRequest,
}
