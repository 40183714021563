import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'invoice',
	meta: {
		keepAlive: true
	},
	component: () => import('../views/invoice.vue')
}, {
	path: '/login',
	name: 'login',
	component: () => import('../views/login.vue')
}]

const router = new VueRouter({
	routes,
	mode: 'history',
})

export default router
