import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
Vue.config.productionTip = false
import Vuex from 'vuex';
Vue.use(Vuex)
import './plugins/axios'
import config from './config/config.js';
// import 'lib-flexible'
const store = new Vuex.Store({
	state: {
		user: {
			token: ''
		},
		code: '',
		goodsList: [],
		checkToken: 0,
		inviteCode: ''
	},
	mutations: {
		setToken(state, token) {
			state.user.token = token
		},
		setUser(state, user) {
			state.user = user;
		},
		setCode(state, code) {
			state.code = code
		},
		clearCode(state) {
			state.code = ''
		},
		clearUser(state) {
			state.user = {}
		},
		setOpenId(state, openid) {
			state.user.openid = openid
		},
		setGoodsList(state, goodsList) {
			state.goodsList = goodsList
		},
		setCheckToken(state, checkToken) {
			state.checkToken = checkToken
		},
		setInviteCode(state, inviteCode) {
			state.inviteCode = inviteCode
		},
	}
})



router.beforeEach((to, from, next) => {
	if (localStorage.getItem("user") != null && localStorage.getItem("user")) {
		//存在登录信息就从localStorage中提取状态再传值给vuex中
		let user = JSON.parse(localStorage.getItem("user"))
		store.commit("setUser", user);
		if (!store.state.checkToken) {
			let params = new FormData()
			axios.post(config.requestUrl + '/front/user/getUserTokenInfo', params, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'token': store.state.user.token
				}
			}).then(response => {
				store.commit('setCheckToken', 1)
				if (response.data.code == 0) {
					if (response.data.obj < 600) {
						localStorage.removeItem("user");
						store.commit("clearUser");
						store.commit("clearCode");
					}
				} else {
					localStorage.removeItem("user");
					store.commit("clearUser");
					store.commit("clearCode");
				}
				next();
			}, response => {
				next()
			})
		} else {
			next();
		}
	} else {
		store.commit("clearUser");
		next();
	}
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
